




import { defineComponent, computed } from '@vue/composition-api';
import flatten from 'lodash/flatten';
import forEach from 'lodash/forEach';
import min from 'lodash/min';
import max from 'lodash/max';
import numeral from 'numeral';
import { $DYNAMIC_REPORT_CONFIGS } from '../composables/config';
import { formatData } from '../utils';


export default defineComponent({
  props: {
    data: {
      required: true,
      type: Object,
    },
  },
  setup(props, context) {
    const { $i18n } = context.root;
    const apiData = props.data;

    const keyMetrictrend = apiData.key_metric_trends;
    const filteredData = {};
    const years = Object.keys(keyMetrictrend);
    const dataLabels = [
      'total_income',
      'net_profit',
      'total_liability',
      'cash',
      'shareholder_equity',
      'non_current_asset',
    ];

    const dataSet = dataLabels.map(label => ({
      name: $i18n.t(label),
      data: years.map(year => keyMetrictrend[year][label]),
    }));

    const propsData = {
      id: 'KeyMetricTrends',
      title: 'Key Metric Trends',
      chartConfigs: {
        series: dataSet,
        xaxis: {
          title: {
            text: 'ปี (ค.ศ)',
          },
          categories: Object.keys(keyMetrictrend),
        },
        yaxis: {
          min: 0,
          max: max(flatten(dataSet.map(item => item.data))) * 1.1,
          tickAmount: 10,
          forceNiceScale: true,
          title: {
            text: 'มูลค่า (บาท)',
            rotate: 0,
            offsetX: 40,
            offsetY: -210,
          },
          labels: {
            formatter: value => formatData(value, '0,0a', null, '฿ '),
          },
        },
        tooltip: {
          y: {
            formatter: value => formatData(value, '0,0', null, '฿ '),
          },
        },
      },
    };
    return {
      propsData,
    };
  },
});
